// Load CCSS

import "./css/tiny-slider.css";
// Load Modules

// import Main from "./modules/main";

// https://www.npmjs.com/package/tiny-slider
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";

// const main = new Main();

const customerSlider = tns({
  container: ".customer-logos",
  autoplay: false,
  controlsPosition: "bottom",
  nav: false,
  controlsText: [
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>',
  ],
  items: 1,
  responsive: {
    640: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
});

const workSlider = tns({
  container: ".work-slider",
  autoplay: false,
  controlsPosition: "bottom",
  controlsText: [
    '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"/></svg>',
    '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none"/></g><g><polygon points="6.23,20.23 8,22 18,12 8,2 6.23,3.77 14.46,12"/></g></svg>',
  ],
  items: 1,
});

// Navbar Event

(function () {
  if (document.body.classList.contains("home")) {
    var collapseNav = document.getElementById("navbarNavDropdown");
    collapseNav.addEventListener("show.bs.collapse", function () {
      document.body.classList.add("mobile-nav-is-open");
    });
    collapseNav.addEventListener("hide.bs.collapse", function () {
      document.body.classList.remove("mobile-nav-is-open");
    });
  }
})();
